/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { postLogin } from "../Utils/api-service";

export default function Login() {
  const navigate = useNavigate();
  const [login, setLogin] = React.useState({
    phone: "",
    passcode: "",
  });
  const [showErr, setShowErr] = React.useState(false);
  // React.useEffect(() => {
  //   localStorage.clear();
  // }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (login.phone.length === 0 || login.passcode.length === 0) {
      setShowErr(true);
      return;
    }
    if (login.phone.length !== 10) {
      setShowErr(true);
      return;
    }
    postLogin(login)
      .then((response) => {
        if (response?.status === 200) {
          localStorage.setItem("authToken", response?.data?.accessToken);
          localStorage.setItem("price", response?.data?.showPrice);
          localStorage.setItem("isShowPriceEnabled", response?.data?.showPrice);
          navigate("/");
        } else if (response?.response?.status === 404) {
          setShowErr(true);
        }
      })
      .catch((err) => {
        setShowErr(true);
      });
  };
  return (
    <>
      <img
        src="assets/images/design.svg"
        className="img-fluid design-top"
        alt=""
      />
      <section className="form-section px-15 top-space section-b-space">
        <h1>
          Hey, <br />
          Login Now
        </h1>
        <form>
          <div className="form-floating mb-4">
            <input
              type="number"
              className="form-control"
              id="one"
              placeholder="Mobile No."
              value={login.phone}
              onChange={(e) => {
                setLogin((prevData) => ({
                  ...prevData,
                  phone: e.target.value,
                }));
                setShowErr(false);
              }}
            />
            <label htmlFor="one">Mobile No.</label>
          </div>
          <div className="form-floating mb-2">
            <input
              type="number"
              className="form-control"
              id="two"
              placeholder="Passcode"
              value={login.passcode}
              onChange={(e) => {
                setLogin((prevData) => ({
                  ...prevData,
                  passcode: e.target.value,
                }));
                setShowErr(false);
              }}
            />
            <label htmlFor="two">Passcode</label>
          </div>
          {showErr && (
            <section className="alert-classic pt-0">
              <div
                className="alert alert-danger d-flex align-items-center"
                role="alert"
              >
                <i className="iconly-Danger icli"></i>
                <div>Mobile No. or Passcode is invalid.</div>
              </div>
            </section>
          )}
          <button onClick={handleSubmit} className="btn btn-danger w-100">
            Sign in
          </button>
        </form>
        <div className="or-divider">
          <strong>
            {" "}
            <span>If you are new</span>
          </strong>
        </div>
        <div className="bottom-detail text-center mt-3">
          <h4 className="content-color">
            <a
              href="callto:917011487840"
              className="title-color text-decoration-underline"
            >
              Connect to sales
            </a>
          </h4>
        </div>
      </section>
      <section className="panel-space"></section>
    </>
  );
}
