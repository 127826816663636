/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import parse from "html-react-parser";
import Slider from "react-slick";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Loader from "../Components/Common/Loader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getProductById, getProductRecomendation } from "../Utils/api-service";
import { extractNumberFromString } from "../Utils/util-service";
import { getCart, setCart } from "../Utils/cart-service";
import AppContext from "../Utils/appContext";
import { possibleNames } from "../Utils/constant";
import SimilarProduct from "../Components/SimilarProduct";
import HeaderOptions from "../Components/Common/HeaderOptions";

export default function ProductDetail() {
  const { setCartLength, showPrice } = React.useContext(AppContext);
  let settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "30px",
    dots: true,
    arrows: false,
  };
  const sendSettings = () => {
    settings.infinite =
      product?.images?.nodes && product?.images?.nodes.length > 1;
    return settings;
  };

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [showErr, setShowErr] = React.useState(false);
  const [price, setPrice] = React.useState();
  const [product, setProduct] = React.useState({});
  // selectedOption will be like [{index: 0, value: 1.5D}]
  const [selectedOption, setSelectedOption] = React.useState([]);
  const [activeIndices, setActiveIndices] = React.useState([]);
  let [searchParams] = useSearchParams();
  const productId = searchParams.get("productId");

  const [quantity, setQuantity] = React.useState(1);
  const [totalPc, setTotalPc] = React.useState();
  const [unit, setUnit] = React.useState();
  // Cart config
  const [cart, setCartState] = React.useState(getCart());
  const [productRecommendations, setProductRecommendations] = React.useState(
    []
  );
  const [selectedImage, setSelectedImage] = React.useState();
  React.useEffect(() => {
    setCart(cart);
  }, [cart]);

  //
  let packSizePosition;

  React.useEffect(() => {
    if (productId) {
      fetchProduct(productId);
      fetchProductRecomendation(productId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);
  const fetchProduct = (id) => {
    setLoading(true);
    try {
      let res = getProductById(id);
      res.then((data) => {
        if (data.status === 200) {
          const ps = data?.data?.product || [];
          setProduct(ps);
          setLoading(false);
          setQuantity(1);
          setDisabled(false);
          console.log(data.data.product);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchProductRecomendation = (id) => {
    try {
      let res = getProductRecomendation(productId);
      res
        .then((data) => {
          if (data.status === 200) {
            setProductRecommendations(data?.data?.productRecommendations || []);
          }
        })
        .catch((err) => {
          setProductRecommendations([]);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const splitTitleToArray = (title) =>
    title.split(" / ").map((str) => str.trim());

  React.useEffect(() => {
    if (product?.options) {
      setActiveIndices(
        product?.options?.length
          ? Array(product?.options?.length).fill(null)
          : null
      );
      if (product?.totalInventory === 0) {
        setDisabled(true);
      }
      if (product?.variants?.nodes.length === 1) {
        setPrice(product?.priceRange?.minVariantPrice?.amount);
        setTotalPc(1);
        setUnit("PC");
      }
      product?.options?.forEach((option, indx) => {
        if (possibleNames.includes(option?.name.toLowerCase())) {
          packSizePosition = indx;
        }
      });
    }
    let found = false;
    // Check if all variant has all zero  quantityAvailable
    let weHaveNothingToSelect;
    let variantLengths = 0;
    //  // Check if all variant has all zero  quantityAvailable ends
    if (product?.variants && product?.variants?.nodes) {
      product?.variants?.nodes?.forEach((variant, indx) => {
        const title = variant?.title;
        const arr = splitTitleToArray(title);
        if (variant?.quantityAvailable > 0) {
          if (!found) {
            // onload option selected
            setSelectedOption(arr);
            const extractData = extractNumberFromString(arr[packSizePosition]);
            setTotalPc(extractData?.number || 1);
            setUnit(extractData?.unit || "PC");
            setPrice(variant?.price?.amount);
          }
          variant.compareQty = arr;
          found = true;
        } else {
          variant.compareQty = arr;
          if (indx === 0) {
            weHaveNothingToSelect = arr;
          }
          variantLengths += 1;
        }
      });
      if (variantLengths === product?.variants?.nodes?.length) {
        setSelectedOption(weHaveNothingToSelect);
        const extractData = extractNumberFromString(weHaveNothingToSelect[0]);
        setTotalPc(extractData?.number || 1);
        setUnit(extractData?.unit || "PC");
        setPrice(product?.variants?.nodes[0]?.price?.amount);
      }
    }
  }, [product?.options]);

  // compare array
  function commonElements(arr1, arr2) {
    if (arr1?.length > 0 && arr1?.length > 0) {
      return arr1.every((element) => arr2.includes(element));
    }
    return false;
  }

  const selectOption = (arrayIndex, itemIndex, optionVal) => {
    // Below code help in selecting options and setting their class to active
    const newActiveIndices = [...activeIndices];
    newActiveIndices[arrayIndex] = itemIndex;
    setActiveIndices(newActiveIndices);
    // Below code ends

    // Set selected option
    const optionsArr = [...selectedOption];
    optionsArr[arrayIndex] = optionVal;
    setSelectedOption(optionsArr);
    const extractData = extractNumberFromString(optionsArr[packSizePosition]);
    setTotalPc(extractData?.number || 0);
    setUnit(extractData?.unit || "PC");

    let returnVal;
    let found = false;
    if (product?.variants && product?.variants?.nodes) {
      product?.variants.nodes.forEach((variant) => {
        if (found) return; // Skip iteration if already found
        if (commonElements(variant.compareQty, optionsArr)) {
          setPrice(variant?.price?.amount);
          returnVal = variant?.quantityAvailable;
        }
      });
    }
    setShowErr(returnVal < 1);
    setDisabled(returnVal < 1);
  };

  const comparePackSize = (arrayIndex, name, val) => {
    let returnVal = true;

    if (possibleNames.includes(name.toLowerCase())) {
      packSizePosition = arrayIndex;
      let options = [...selectedOption];
      options = options.filter((_, i) => i !== arrayIndex);
      options[arrayIndex] = val;
      if (product?.variants && product?.variants?.nodes) {
        product?.variants.nodes.forEach((variant) => {
          if (commonElements(variant.compareQty, options)) {
            returnVal = variant?.quantityAvailable > 0;
          }
        });
      }
      return returnVal;
    } else {
      return returnVal;
    }
  };

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const handleAddToCart = () => {
    const selectedProduct = {
      id: productId,
      title: product.title,
      price,
      totalPrice: price * quantity,
      quantity,
      totalPc,
      unit,
      selectedOption,
      options: product?.options,
      vendor: product?.vendor,
      productType: product?.productType,
      tags: product?.tags || [],
      images: product?.images?.nodes || [],
      unitPrice: (price / totalPc).toFixed(2),
    };
    setCartState((prevCart) => {
      const newCart = [...prevCart, selectedProduct];
      return newCart;
    });
    setTimeout(() => {
      const carts = getCart();
      setCartLength(carts.length);
      navigate("/cart");
    }, 100);
  };
  return (
    <>
      {loading && <Loader />}
      <header>
        <div onClick={() => navigate(-1)} className="nav-bar">
          <i className="iconly-Arrow-Left icli"></i>
        </div>
        <a onClick={() => navigate("/")} className="brand-logo">
          <img src="assets/images/logo.png" className="img-fluid" alt="" />
        </a>
        <HeaderOptions />
      </header>
      <section className="product-page-section top-space pt-0">
        {/* <div className="rate-section px-15">
          <ul>
            <li>
              <i className="iconly-Home icli"></i> Home
            </li>
            <li>
              <i className="iconly-Arrow-Right-2 icli"></i>Categories
            </li>
            <li>
              <i className="iconly-Arrow-Right-2 icli"></i>Sub Category
            </li>
          </ul>
        </div> */}

        <Slider
          className={
            "home-slider slick-default theme-dots ratio_asos overflow-hidden"
          }
          {...sendSettings()}
        >
          {product?.images?.nodes &&
            product?.images?.nodes.length > 0 &&
            product?.images?.nodes.map((val, idx) => {
              return (
                <div
                  onClick={() => setSelectedImage(val?.url || undefined)}
                  key={idx}
                >
                  <div className="home-img">
                    <img
                      data-bs-toggle="modal"
                      data-bs-target="#filterModal"
                      src={val?.url}
                      className="img-fluid bg-img"
                      alt=""
                    />
                  </div>
                </div>
              );
            })}
        </Slider>
        <div className="product-detail-box px-15 pt-2">
          <div className="main-detail">
            <h2 className="text-capitalize">{product?.title}</h2>
            <div className="price">
              {showPrice && <h2 className="text-green">₹{price}</h2>}{" "}
              {product?.totalInventory === 0 && (
                <h4>
                  <span>OUT OF STOCK</span>
                </h4>
              )}
            </div>
            {/* <h6 className="text-green">inclusive of all taxes</h6> */}
          </div>
        </div>
        <div className="divider"></div>
        <div className="product-detail-box px-15">
          {product?.options &&
            product?.options.length > 0 &&
            product?.options.map((option, arrayIndex) => {
              if (option?.name === "Title") {
                return null;
              }
              return (
                <div key={arrayIndex} className="filter-box">
                  <h2
                    id={
                      arrayIndex > 0 ? "product-option-spacingfrom-2" : "none"
                    }
                    className="filter-title"
                  >
                    {option?.name}:
                  </h2>
                  <div className="filter-content">
                    <ul className="row filter-row g-3">
                      {option?.values &&
                        option?.values.map((val, itemIndex) => {
                          return (
                            <li
                              key={itemIndex}
                              id={
                                comparePackSize(arrayIndex, option?.name, val)
                                  ? ""
                                  : "estimate-strike"
                              }
                              className={
                                selectedOption[arrayIndex] === val
                                  ? "col-4 active"
                                  : "col-4"
                              }
                              onClick={() =>
                                selectOption(arrayIndex, itemIndex, val)
                              }
                            >
                              <div className="filter-col">{val}</div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              );
            })}
          <div className="size-detail">
            <h2 id="product-option-spacingfrom-2" className="size-title">
              QUANTITY:
            </h2>
            <div id="estimate-qty-product" className="qty-counter">
              <li id="estimate-selected-product" className="col-6">
                <h6 id="color-orange" className="content-color">
                  Total Quantity :{" "}
                  <strong>
                    {" "}
                    {totalPc * quantity || ""} {unit}
                  </strong>
                </h6>
                <span className="content-color">
                  {/* {price} {totalPc} */}
                  {showPrice &&
                    price &&
                    totalPc &&
                    unit &&
                    `@ ₹${(price / totalPc).toFixed(2)} per ${unit}`}
                </span>
                {showPrice && (
                  <h6 className="text-green">
                    Total Amount: <strong> ₹{price * quantity}</strong>
                  </h6>
                )}
                {/* <div className="filter-col">9000 Pcs (90/Pc)</div> */}
              </li>
              <div id="estimate-height-input" className="input-group">
                <button
                  type="button"
                  className="btn quantity-left-minus"
                  onClick={handleDecrement}
                >
                  <img
                    src="assets/svg/minus-square.svg"
                    className="img-fluid"
                    alt=""
                  />
                </button>
                <input
                  type="text"
                  name="quantity"
                  readOnly
                  className="form-control form-theme qty-input input-number"
                  value={quantity}
                />
                <button
                  type="button"
                  className="btn quantity-right-plus"
                  onClick={handleIncrement}
                >
                  <img
                    src="assets/svg/plus-square.svg"
                    className="img-fluid"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
          {showErr && (
            <section className="alert-classic pt-0">
              <div
                className="alert alert-danger d-flex align-items-center"
                role="alert"
              >
                <i className="iconly-Danger icli"></i>
                <div>Please select different pack quantity.</div>
              </div>
            </section>
          )}
          <button
            disabled={disabled}
            id="estimate-100"
            type="button"
            className="btn btn-danger"
            onClick={() => handleAddToCart()}
          >
            Add To Cart
          </button>
        </div>
        <div className="divider"></div>
        <div className="product-detail-box px-15">
          <h2 className="page-title mb-1">Product Details</h2>
          {product?.descriptionHtml && parse(product?.descriptionHtml)}
        </div>
        {/* <div className="divider"></div> */}
        <section class="brand-section px-15">
          <h2 class="title">Compatible With</h2>
          <div class="row g-3">
            <div className="col-4">
              <a className="brand-box" href="#">
                <img
                  src="assets/images/brand-logos/samsung.jpg"
                  className="img-fluid"
                  alt=""
                />
              </a>
            </div>
            <div className="col-4">
              <a className="brand-box" href="#">
                <img
                  src="assets/images/brand-logos/lg.jpg"
                  className="img-fluid"
                  alt=""
                />
              </a>
            </div>
            <div className="col-4">
              <a className="brand-box" href="#">
                <img
                  src="assets/images/brand-logos/daikin.jpg"
                  className="img-fluid"
                  alt=""
                />
              </a>
            </div>
          </div>
        </section>
        <div className="divider"></div>
      </section>
      {productRecommendations && productRecommendations.length > 0 && (
        <section className="pt-0 product-slider-section overflow-hidden">
          <div className="title-section px-15">
            <h2>Similar Products</h2>
          </div>
          <SimilarProduct recommendedProducts={productRecommendations} />
        </section>
      )}
      <section className="panel-space"></section>
      <div
        className="modal filter-modal fade"
        id="filterModal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <a
                href="javascript:void(0)"
                className="close-modal"
                data-bs-dismiss="modal"
                onClick={() => setSelectedImage()}
              >
                <img src="assets/svg/x-dark.svg" className="img-fluid" alt="" />
              </a>
            </div>
            <div className="modal-body">
              {selectedImage && (
                <TransformWrapper pinch={{ step: 2 }} disablePadding>
                  <TransformComponent
                    wrapperStyle={{ width: "auto", height: "100%" }}
                  >
                    <img
                      src={selectedImage}
                      style={{ width: "100%" }}
                      className="bg-img"
                      alt=""
                    />
                  </TransformComponent>
                </TransformWrapper>
              )}
            </div>
            <div className="modal-footer">
              <a
                href="javascript:void(0)"
                className="reset-link"
                data-bs-dismiss="modal"
                onClick={() => setSelectedImage()}
              >
                CLOSE
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
